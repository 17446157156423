import React, { useContext } from 'react';
import { Context } from "../assets/context";
import { Navigate } from 'react-router-dom';
import { random_build } from '../funcs/build';

export default () => {

    // GLOBAL STATE
    const { state } = useContext(Context);

    // GENERATE RANDOM BUILD
    const { race, block } = random_build(state)

    // REDIRECT
    return (
        <Navigate to={ '/' + race + '#' + block } />
    )
}