import React, { useContext, useEffect } from 'react';
import '../interface/css/innerbody.scss';

import { useParams, useNavigate } from 'react-router-dom'
import { Context } from "../assets/context";
import { specific_build } from '../funcs/build';
import Map from '../components/map';
import Panel from '../components/panel';
import Error from '../pages/error';

export default ({ isMobile }) => {

  // GLOBAL STATE
  const { state, dispatch } = useContext(Context);

  // EXTRACT BUILD PARAMS & EXPOSE URL NAVIGATOR
  const { race } = useParams()

  const block = window.location.hash.substring(1)

  const navigator = useNavigate()

  // ON LOAD..
  useEffect(() => {

    // EXPOSE NAVIGATOR API
    dispatch({
      type: 'navigator',
      payload: navigator
    })

    // ATTEMPT TO FETCH TARGET BUILD
    const response = specific_build(state, race, block)

    // IF EVERYTHING WENT OK, SET BUILD IN STATE
    if (response.success) {
      dispatch({
        type: 'load-build',
        payload: response.data
      })
    }


  }, [])


  // WHEN A VALID BUILD WAS LOADED, RENDER PAGE
  if (state.build.loaded) {
    if (isMobile) {
      return (
        <div id={'innerbody-mobile'}>
          <Map isMobile={isMobile} />
          <Panel isMobile={isMobile} />
        </div>
      )
    } else {
      return (
        <div id={'innerbody'}>
          <div className={'inner'}>
            <Map isMobile={isMobile} />
            <Panel isMobile={isMobile} />
          </div>
        </div>
      )
    }

  } else {
    return (
      <Error message={'ROUTE DOES NOT EXIST'} />
    )
  }
}