import React, { useContext, useState, useEffect } from 'react';
import { Context } from "../../../assets/context";
import { jump } from "../../../funcs/browsing";

export default ({isMobile}) => {

    // GLOBAL STATE
    const { state, dispatch } = useContext(Context);

    // LOCAL PROGRESS STATE
    const [local, set_local] = useState({
        progress: null,
        width: {},
        isMobile: isMobile
    })

    // UPDATE PROGRESS
    useEffect(() => {

        // COMPUTE PERCENTAGE
        const current = ((state.build.current / (state.build.route.length - 1)) * 100).toFixed(2) + '%';

        // UPDATE LOCAL STATE
        set_local({
            progress: current,
            width: {
                width: current
            },
            isMobile: isMobile
        })

    }, [state.build])

    return (
        <div id="progress" onClick={(event) => { jump(event, state, dispatch, local.isMobile) }}>
            <div className="split">
                <div>Progress</div>
                <div>{ local.progress }</div>
                <div id="bar" style={ local.width } />
            </div>
        </div>
    )
}