// DEFAULT VALUES
const values = {
    background: {},
    markers: [],
    position: {},
    transition: {},
    movement: {
        last_position: {},
        last_event: {},
        enabled: false
    }
}

// REDUCER
function reducer(state, { type, payload }) {
    switch (type) {

        // UPDATE MARKERS
        case 'markers': { return {
            ...state,
            markers: payload
        }}

        // UPDATE POSITION
        case 'position': { return {
            ...state,
            position: payload.coords,
            movement: {
                ...state.movement,
                last_position: payload.position
            }
        }}

        // ENABLE MAP MOVEMENT
        case 'enable-movement': { return {
            ...state,
            transition: {
                transition: 'none'
            },
            movement: {
                ...state.movement,
                enabled: true,
                last_event: payload
            }
        }}

        // DISABLE MAP MOVEMENT
        case 'disable-movement': { return {
            ...state,
            transition: {
                transition: '0.2s'
            },
            movement: {
                ...state.movement,
                enabled: false
            }
        }}

        // MOVE MAP
        case 'move': { return {
            ...state,
            position: payload.coords,
            movement: {
                ...state.movement,
                last_event: payload.event,
                last_position: payload.position
            }
        }}

        // OTHERWISE, LOG ERROR
        default: {
            console.log('MAP STATE REDUCER ERROR');
            return state;
        }
    }
}

export {
    values,
    reducer
}