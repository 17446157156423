import React, { useContext } from 'react';
import { Context } from "../../../assets/context";
import { specific_build } from '../../../funcs/build';

import Main from './main';

export default () => {

    // GLOBAL STATE
    const { state, dispatch } = useContext(Context);
    function loadProgress(event, name, race, block) {

      const response = specific_build(state, race, block)
      // IF EVERYTHING WENT OK, SET BUILD IN STATE
      if (response.success) {
          dispatch({
              type: 'load-build',
              payload: response.data
          })

          dispatch({
            type: 'set-active-character',
            payload: name
          })

          let currentUrlWithoutParams = window.location.pathname.substring(0,window.location.pathname.indexOf("leveling") + 9)
          window.history.replaceState(null, document.title, currentUrlWithoutParams + race + '#' + block)
      }

    }

    var progressData = localStorage.getItem(`${process.env.REACT_APP_EXPANSION}-questing`)

    var renderer = [];
    if (progressData) {
      progressData = JSON.parse(progressData)
      var characters = Object.keys(progressData)
      for (var i = 0; i < characters.length; i++) {
          const name = characters[i]
          const race = progressData[name].race
          const block = progressData[name].block
  
          renderer.push(
            <div key={i} onClick={ event => { loadProgress(event, name, race, block) } }>
            <div className="item">
            <div className='icon' id={ race } />
            <div className='header'>{ name }</div>
            </div>
            </div>
          )
      }
    }

   
    return <Main header={ 'Load Progress' }>{renderer} </Main>;
}