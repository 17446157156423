// DEFAULT VALUES
const values = {

    expansion: 'classic',

    // DATASET
    dataset: null,
    loaded: false,

    // TARGET BUILD
    build: {
        loaded: false
    },

    // CHARACTER IN PROGRESS
    activeChar: null,

    // URL NAVIGATOR
    navigator: null,

    // PROMPT RELATED
    prompt: {
        visible: false,
        race: null,
        type: null
    }
}

// REDUCER
function reducer(state, { type, payload }) {
    switch (type) {
        // ON THE INITIAL PAGE LOAD
        case 'init': { return {
            ...state,
            dataset: payload,
            loaded: true
        }}

        // LOAD ROUTE BUILD
        case 'load-build': { return {
            ...state,
            build: {
                ...payload,
                loaded: true
            }
        }}

        // EXPOSE URL NAVIGATOR API
        case 'navigator': { return {
            ...state,
            navigator: payload
        }}

        // UPDATE CURRENT BLOCK
        case 'block': { return {
            ...state,
            build: {
                ...state.build,
                current: payload
            }
        }}

        // SHOW SPECIFIC PROMPT
        case 'show-prompt': { return {
          ...state,
          prompt: {
              visible: true,
              type: payload.promptType,
              race: payload.promptRace
          }
        }}

        // HIDE PROMPT
        case 'hide-prompt': { return {
          ...state,
          prompt: {
              ...state.prompt,
              visible: false
          }
        }}

        case 'set-active-character': { 
          return {
          ...state,
          activeChar: payload
        }}

        // OTHERWISE, LOG ERROR
        default: {
            console.log('GLOBAL STATE REDUCER ERROR');
            return state;
        }
    }
}

export {
    values,
    reducer
}