import React, { useState, useContext, useEffect } from "react";
import "../interface/css/map.scss";

import { Context } from "../assets/context";
import { dimensions } from "../funcs/map";
import {
  next,
  previous,
  update_clipping,
  remove_clipping,
  update_state
} from "../funcs/browsing";
import { sleep } from "../funcs/misc";
import PrevNext from "./map/prevnext";
import Container from "./map/container";

export default ({ isMobile }) => {
  // GLOBAL STATE
  const { state, dispatch } = useContext(Context);
  // LOCAL STATE
  const [local, set_local] = useState({
    prevnext: false,
    resolution: null,
    isMobile: isMobile,
  });

  // SHOW/HIDE PREVNEXT PANELS
  const prevnext = {
    show: function () {
      set_local({
        ...local,
        prevnext: true,
      });
    },
    hide: function () {
      set_local({
        ...local,
        prevnext: false,
      });
    },
  };

  // UPDATE RESOLUTION
  function update_resolution() {
    update_state(state, dispatch,state.build.current)
    set_local({
      ...local,
      resolution: dimensions(),
    });
    if (local.isMobile) {
      const waypoints = state.build.route[state.build.current].waypoints;
      update_clipping(waypoints);
    } else {
      remove_clipping();
    }
  }

  // ON LOAD..
  useEffect(() => {
    sleep(200).then(() => {
      // WAIT FOR MAP SELECTOR RESOLUTION TO SETTLE, THEN MEASURE SELECTOR
      
      // RESOLUTION MUST BE UPDATED WHEN PAGE IS RESIZED
      window.addEventListener("resize", () => {
        update_resolution();
      });

      // WAIT ANOTHER MILLISECOND, THEN TRANSITION OPACITY
      sleep(100).then(() => {
        update_resolution();
        document.getElementById("map").style.opacity = 1;
      });
    });

    // remove the event listener when component unmounts
    return () => {
      window.removeEventListener("resize", () => {
        update_resolution();
      });
    };
  }, []);

  return (
    <div
      id={"map-wrapper"}
      onMouseOver={prevnext.show}
      onMouseOut={prevnext.hide}
    >
      <Container resolution={local.resolution} isMobile={local.isMobile} />
      <PrevNext
        type={"prev"}
        visibility={local.prevnext}
        action={() => {
          previous(state, dispatch, local.isMobile);
        }}
        resolution={local.resolution}
      />
      <PrevNext
        type={"next"}
        visibility={local.prevnext}
        action={() => {
          next(state, dispatch, local.isMobile);
        }}
        resolution={local.resolution}
      />
    </div>
  );
};
