import React, {useState, useEffect} from 'react';

export default ({ waypoint, block, offcolor, resolution }) => { 
  

    // Circles should be rendered again when resolution is changed.

    // LOCAL STATE
      const [local, set_local] = useState({
        scale: 1
    })

    useEffect(() => {
      // UPDATE LOCAL STATE
      const map = document.getElementById('map')

      if (map) {
        set_local({
          scale: map.clientWidth / 1440
        })
      }
    }, [resolution])
  
  
  return (
    <circle
        cx={ waypoint.coords.x + '%' }
        cy={ waypoint.coords.y * local.scale + '%' }
        r={ 20 }
        fill={ offcolor ? 'rgb(28, 68, 117)' : 'rgb(255, 39, 39)' }
        id={ 'circle-' + block }
    />
)}