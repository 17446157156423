import React, { Fragment } from 'react';

import { extract } from '../../../funcs/quests';

import Single from '../single';
import Split from '../split';

export default ({ quest, state }) => { return (
    <Fragment>
        <span className={ 'starts-icon' } />
        <div className={ 'starts-row' }>
            <Row
                quest={ quest }
                state={ state }
            />
        </div>
    </Fragment>
)}

// QUEST ROW
function Row({ quest, state }) {

    // FETCH QUEST ID & NAME, THEN URL & LOCALIZATION PREFIX
    const { name, id } = extract(quest, state);

    // DETERMINE CONTAINER
    switch(typeof quest) {

        // ARRAYS
        case 'object': { return (
            <Split
                header={ name }
                tag={ quest[1] }
                url={ process.env.REACT_APP_DB_QUEST_LINK }
                id={ id }
            />
        )}

        // STRINGS
        default: { return (
            <Single
                header={ name }
                url={ process.env.REACT_APP_DB_QUEST_LINK }
                id={ id }
            />
        )}
    }
}