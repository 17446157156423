import React, { useState, useContext, Fragment } from 'react';
import EventListener from 'react-event-listener';

import { Context } from "../../assets/context";
import { specific_build } from '../../funcs/build';

export default () => {

    // ROUTE CONTEXT
    const { state, dispatch } = useContext(Context);

    // LOCAL STATE
    const [local, set_local] = useState({
        level: '',
        button: 'bad'
    })

    // CHECK IF THE LEVEL REQUEST ABIDES BY THE RULESET
    const audit = (event) => {
        if (event.target.value.length !== 0 && event.target.value.length >= 0 && event.target.value.length <= 70) {
          set_local({
                level: event.target.value,
                button: 'good',
            })
        } else {
            set_local({
                level: event.target.value,
                button: 'bad',
            })
        }
    }

    // WHEN SUBMIT BUTTON IS PRESSED & NO ERRORS HAVE OCCURRED
    const submit = () => {
      if (local.button === 'good') {

            // LOAD BUILD
            const build = specific_build(
                state,
                state.prompt.race,
                0
            )

            if (build.success) {
              dispatch({
                type: 'load-build',
                payload: build.data
              })
            }
            // UPDATE URL
            let currentUrlWithoutParams = window.location.pathname.substring(0,window.location.pathname.indexOf("leveling") + 9)
            window.history.replaceState(null, document.title, currentUrlWithoutParams + state.prompt.race + '#0')
          
            // SAVE LOCAL STORAGE
            let currentProgressData = localStorage.getItem(`${process.env.REACT_APP_EXPANSION}-questing`)
            currentProgressData = currentProgressData ? JSON.parse(currentProgressData) : {}

            currentProgressData[local.level] = {
              race: state.prompt.race,
              block: 0,
            }

            localStorage.setItem(`${process.env.REACT_APP_EXPANSION}-questing`, JSON.stringify(currentProgressData))

            // RESET LOCAL INPUT
            set_local({
                name: '',
                button: 'bad',
            })

            // SET CHARACTER IN PROGRESS
            dispatch({ type:'set-active-character', payload: local.level})

            // HIDE PROMPT
            dispatch({ type: 'hide-prompt' })
        }
    }

    // ENTER KEY LISTENER
    const key_listener = (event) => {
        if (event.key.toLowerCase() === 'enter' && state.prompt.visible) {
            submit();
        }
    }

    return (
        <Fragment>
            <div id={ 'header' }>Name</div>
            <div id={ 'create' }>
                <EventListener
                    target={ 'window' }
                    onKeyDown={ key_listener }
                />
                <input
                    id={ 'content' }
                    type={ 'text' }
                    placeholder={ 'Player' }
                    onChange={ audit }
                    value={ local.name }
                    autoFocus
                />
                <input
                    id={ local.button }
                    type={ 'submit' }
                    value={ 'Build' }
                    onClick={ submit }
                />
            </div>
        </Fragment>
    )
}