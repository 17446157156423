
// ALLIANCE VANILLA ROUTES
import human_vanilla from '../routes/wow-classic/alliance/human.json';
import gnome_vanilla from '../routes/wow-classic/alliance/gnome.json';
import dwarf_vanilla from '../routes/wow-classic/alliance/dwarf.json';
import nelf_vanilla from '../routes/wow-classic/alliance/nelf.json';

// ALLIANCE TBC ROUTES
import human_tbc from '../routes/tbc/alliance/human.json';
import gnome_tbc from '../routes/tbc/alliance/gnome.json';
import dwarf_tbc from '../routes/tbc/alliance/dwarf.json';
import nelf_tbc from '../routes/tbc/alliance/nelf.json';

// ALLIANCE WOTLK ROUTES
import human_wotlk from '../routes/wotlk/alliance/human.json';
import gnome_wotlk from '../routes/wotlk/alliance/gnome.json';
import dwarf_wotlk from '../routes/wotlk/alliance/dwarf.json';
import nelf_wotlk from  '../routes/wotlk/alliance/nelf.json';

// ALLIANCE QUEST IDS
import alliance_vanilla_ids from '../routes/wow-classic/alliance/alliance_ids.json'
import alliance_tbc_ids from '../routes/tbc/alliance/alliance_ids.json'
import alliance_wotlk_ids from '../routes/wotlk/alliance/alliance_ids.json'

// ALLIANCE SHARED
import alliance_shared_vanilla from '../routes/wow-classic/alliance/shared.json'
import alliance_shared_tbc from '../routes/tbc/alliance/shared.json'
import alliance_shared_wotlk from '../routes/wotlk/alliance/shared.json'

// HORDE VANILLA ROUTES
import troll_vanilla from '../routes/wow-classic/horde/troll.json';
import orc_vanilla from '../routes/wow-classic/horde/orc.json';
import tauren_vanilla from '../routes/wow-classic/horde/tauren.json';
import undead_vanilla from '../routes/wow-classic/horde/undead.json';

// HORDE TBC ROUTES
import troll_tbc from '../routes/tbc/horde/troll.json';
import orc_tbc from '../routes/tbc/horde/orc.json';
import tauren_tbc from '../routes/tbc/horde/tauren.json';
import undead_tbc from '../routes/tbc/horde/undead.json';

// HORDE WOTLK ROUTES
import troll_wotlk from '../routes/wotlk/horde/troll.json';
import orc_wotlk from '../routes/wotlk/horde/orc.json';
import tauren_wotlk from '../routes/wotlk/horde/tauren.json';
import undead_wotlk from '../routes/wotlk/horde/undead.json';

// HORDE QUEST IDS
import horde_vanilla_ids from '../routes/wow-classic/horde/horde_ids.json'
import horde_tbc_ids from '../routes/tbc/horde/horde_ids.json'
import horde_wotlk_ids from '../routes/wotlk/horde/horde_ids.json'

// HORDE SHARED
import horde_shared_vanilla from '../routes/wow-classic/horde/shared.json'
import horde_shared_tbc from '../routes/tbc/horde/shared.json'
import horde_shared_wotlk from '../routes/wotlk/horde/shared.json'

// RACE SPECIFIC ROUTES
function load_routes(expansion) {
    switch (expansion) {
      case 'wow-classic': {
        return {
          alliance: {
              human: human_vanilla.path.concat(alliance_shared_vanilla.path),
              gnome: gnome_vanilla.path.concat(alliance_shared_vanilla.path),
              dwarf: dwarf_vanilla.path.concat(alliance_shared_vanilla.path),
              nelf: nelf_vanilla.path.concat(alliance_shared_vanilla.path)
          },
          horde: {
              orc: orc_vanilla.path.concat(horde_shared_vanilla.path),
              troll: troll_vanilla.path.concat(horde_shared_vanilla.path),
              tauren: tauren_vanilla.path.concat(horde_shared_vanilla.path),
              undead: undead_vanilla.path.concat(horde_shared_vanilla.path)
          }
        }
      }

      case 'tbc': {
        return {
          alliance: {
              human: human_tbc.path.concat(alliance_shared_tbc.path),
              gnome: gnome_tbc.path.concat(alliance_shared_tbc.path),
              dwarf: dwarf_tbc.path.concat(alliance_shared_tbc.path),
              nelf: nelf_tbc.path.concat(alliance_shared_tbc.path)
          },
          horde: {
              orc: orc_tbc.path.concat(horde_shared_tbc.path),
              troll: troll_tbc.path.concat(horde_shared_tbc.path),
              tauren: tauren_tbc.path.concat(horde_shared_tbc.path),
              undead: undead_tbc.path.concat(horde_shared_tbc.path)
          }
        }
      }

      case 'wotlk': {
        return {
          alliance: {
              human: human_wotlk.path.concat(alliance_shared_wotlk.path),
              gnome: gnome_wotlk.path.concat(alliance_shared_wotlk.path),
              dwarf: dwarf_wotlk.path.concat(alliance_shared_wotlk.path),
              nelf: nelf_wotlk.path.concat(alliance_shared_wotlk.path)
          },
          horde: {
              orc: orc_wotlk.path.concat(horde_shared_wotlk.path),
              troll: troll_wotlk.path.concat(horde_shared_wotlk.path),
              tauren: tauren_wotlk.path.concat(horde_shared_wotlk.path),
              undead: undead_wotlk.path.concat(horde_shared_wotlk.path)
          }
        }
      }

      default: {
        console.log('DATABASE ERROR');
      }
    }
   
}

// FACTION SPECIFIC QUEST IDS
function load_ids(expansion) {
  switch (expansion) {
    case 'wow-classic': {
      return {
        alliance: {
            ...alliance_vanilla_ids,
        },
        horde: {
            ...horde_vanilla_ids,
        }
      }
    }
    case 'tbc': {
      return {
        alliance: {
            ...alliance_vanilla_ids,
            ...alliance_tbc_ids
        },
        horde: {
            ...horde_vanilla_ids,
            ...horde_tbc_ids
        }
      }
    }
    case 'wotlk': {
      return {
        alliance: {
            ...alliance_vanilla_ids,
            ...alliance_tbc_ids,
            ...alliance_wotlk_ids
        },
        horde: {
            ...horde_vanilla_ids,
            ...horde_tbc_ids,
            ...horde_wotlk_ids
          }
      }
    }
    default: {
      console.log('DATABASE ERROR');
    }
  }
}

export {
    load_routes,
    load_ids
}