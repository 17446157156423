// BROWSE TO PREVIOUS BLOCK
function previous(state, dispatch, isMobile) {

    // REDUCE BY ONE
    const prev = state.build.current - 1;
    const prevWaypoints = state.build.route[prev].waypoints

    // IF ITS STILL WITHIN RANGE, UPDATE STATE
    if (prev >= 0) {
        update_state(state, dispatch, prev)
    }
    if (isMobile) {
      update_clipping(prevWaypoints, dispatch)
    }
}

// BROWSE TO NEXT BLOCK
function next(state, dispatch, isMobile) {

    // INCREASE BY ONE
    const next = state.build.current + 1;
    const nextWaypoints = state.build.route[next].waypoints

    // IF ITS STILL WITHIN RANGE, UPDATE STATE
    if (next <= state.build.route.length - 1) {
        update_state(state, dispatch, next)
    }
    if (isMobile) {
      update_clipping(nextWaypoints)
    }
}

// JUMP IN PROGRESS
function jump(event, state, dispatch, isMobile) {

    // EVENT PARAMS
    // const parameters = {
    //     window: window.innerWidth,
    //     width: event.target.clientWidth,
    //     coord: event.pageX + 7
    // }

    // FIND CLOSEST ROUTE BLOCK
    // const length = parameters.width - (parameters.window - parameters.coord);
    // const percent = length / parameters.width;


    // THE SOLUTION ABOVE DOESN'T WORK IN WORDPRESS BUILD.
    // HERE IS A BETTER APPROACH
    const rect = event.target.getBoundingClientRect();
    const x = event.clientX - rect.left
    const percent = x / event.target.clientWidth

    const adjusted_block = Math.floor(percent * state.build.route.length);

    // UPDATE STATE
    if (adjusted_block > 0 ) {
      update_state(state, dispatch, adjusted_block)
    }

    if (isMobile) {
      update_clipping(state.build.route[adjusted_block].waypoints)
    }
}

// UPDATE STATE
function update_state(state, dispatch, block) {

    // UPDATE CURRENT BLOCK
    dispatch({
        type: 'block',
        payload: block
    })

    update_local_storage(state, block)

    // UPDATE URL
    state.navigator('/' + state.build.race + '#' + block)
}

// UPDATE LOCAL STORAGE
function update_local_storage(state, block) {

  let progressData = localStorage.getItem(`${process.env.REACT_APP_EXPANSION}-questing`)
  if (progressData) {
    progressData = JSON.parse(progressData)
    var active = progressData[state.activeChar]
    if (active) {
      active.block = block
      localStorage.setItem(`${process.env.REACT_APP_EXPANSION}-questing`, JSON.stringify(progressData))
    }
  }
}

function getBBoxOfWaypoint(waypoints) {

  let minX = 100;
  let maxX = 0;  
  let minY = 100;
  let maxY = 0;  

  for (const waypoint of waypoints) {
    const waypointX = waypoint.coords.x
    const waypointY = waypoint.coords.y
    if (waypointX > maxX) { maxX = waypointX; }
    if (waypointX < minX) { minX = waypointX; }
    if (waypointY > maxY) { maxY = waypointY; }
    if (waypointY < minY) { minY = waypointY; }
  }

   // add some padding.
   maxX = Math.min(100, maxX + 15)
   minX = Math.max(0, minX - 15)
   maxY = Math.min(100, maxY + 15)
   minY = Math.max(0, minY - 15)

   return {maxX, minX, maxY, minY}

}

function update_clipping(waypoints) {

  const mapwrapper = document.getElementById("map-wrapper")
  const map = document.getElementById("map");

  let mapWidth = mapwrapper.clientWidth
  let mapHeight = (mapWidth / 1.5).toFixed(0) * 1

  const bbox = getBBoxOfWaypoint(waypoints)
  let minX = bbox.minX;
  let maxX = bbox.maxX;  
  let minY = bbox.minY;
  let maxY = bbox.maxY;  

  const scaleX = Math.min(3, 100 / (maxX - minX)); // no need to scale more than 2 times.
  const scaleY = Math.min(3, 100 / (maxY - minY));

  const fixedScale = Math.min(scaleX, scaleY)

  const centerX = (minX + maxX) / 2
  const centerY = (minY + maxY) / 2

  const left =  (centerX - 50) * mapWidth / 100 * fixedScale;
  const top = (centerY - 50) * mapHeight / 100 * fixedScale;

  map.setAttribute("style", 
  `width: 100%; 
  height: ${mapHeight + "px;"} 
  -webkit-clip-path: polygon(
    ${minX * mapWidth / 100}px ${minY * mapHeight / 100}px,
    ${maxX * mapWidth / 100}px ${minY * mapHeight / 100}px,
    ${maxX * mapWidth / 100}px ${maxY * mapHeight / 100}px,
    ${minX * mapWidth / 100}px ${maxY * mapHeight / 100}px
  ); 
  clip-path: polygon(
    ${minX * mapWidth / 100}px ${minY * mapHeight / 100}px,
    ${maxX * mapWidth / 100}px ${minY * mapHeight / 100}px,
    ${maxX * mapWidth / 100}px ${maxY * mapHeight / 100}px,
    ${minX * mapWidth / 100}px ${maxY * mapHeight / 100}px
  ); 
  left: ${-left.toFixed(0)}px; 
  top: ${-top.toFixed(0)}px; 
  transform: scale(${fixedScale});
  webkit-transform: scale(${fixedScale}); 
  opacity: 1;`)
}

function remove_clipping() {
  const map = document.getElementById("map");
  map.setAttribute("style", `opacity: 1;`)
}


export {
    next,
    previous,
    jump,
    update_clipping,
    remove_clipping,
    update_state,
    getBBoxOfWaypoint
}

