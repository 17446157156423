// WAIT FOR GIVEN MILLISECONDS
function sleep (time) {
    return new Promise((resolve) => setTimeout(resolve, time));
}

// SHORTEN STRING
function shorten(string) {

    // MAX CHARACTER LIMIT
    const max_length = 22;

    // CHECK IF THE STRING LONGER THAN 22 CHARACTERS
    if (string.length > max_length) {

        // ALLOW THE FIRST 20 CHARACTERS AND TAG ON THE TRIPLEDOT
        string = string.substring(0, (max_length - 3));
        string += '...';
    }

    return string;
}

// PARSE HEARTHSTONE BREAKPOINTS
function hs_breakpoints(route) {
    const container = [];

    // LOOP THROUGH WAYPOINTS
    route.forEach((block, index) => {
        block.waypoints.forEach(waypoint => {

            // LOOP THROUGH SPECIALS
            if (waypoint.special !== undefined) {
                waypoint.special.forEach(message => {

                    // WHEN A HEARTHSTONE POSITION IS FOUND, PUSH IT
                    if (typeof message === 'string' && message.toLowerCase() === 'set hearthstone') {
                        container.push({
                            block: index,
                            zone: block.zone
                        })
                    }
                })
            }
        })
    })

    return container;
}

// PARSE ROUTE OPTIONS
function parse_options(routes) {

    // RESPONSE CONTAINER
    const response = {
        races: [],
        factions: {}
    }

    // LOOP THROUGH FACTIONS & RACES
    Object.keys(routes).forEach(faction => {
        Object.keys(routes[faction]).forEach(race => {
            response.races.push(race)
            response.factions[race] = faction
        })
    })
    
    return response
}

export {
    sleep,
    shorten,
    hs_breakpoints,
    parse_options
}