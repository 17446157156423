import React, { useContext } from 'react';
import { Context } from "../../../assets/context";
import { filter } from "../../../funcs/quests";
import Item from './item';

export default ({ visible }) => {

    // GLOBAL STATE
    const { state } = useContext(Context);

    // CONTENT SWAPPER
    switch (visible) {

        // TAB IS VISIBLE, RENDER NORMALLY
        case true: {

            // FILTER PROGRESS
            const content = filter(state);

            return (
                <div id={ 'quests' }>
                    <div className={ 'section' }>
                        <div className={ 'title' }>
                            <div>Current Quest Log</div>
                            <div>{ content.length } / 20</div>
                        </div>
                        { content.map((quest, index) =>
                            <Item
                                key={ index }
                                quest={ quest }
                                state={ state }
                            />
                        )}
                    </div>
                </div>
            )
        }

        // OTHERWISE, RENDER NOTHING
        default: { return null; }
    }
}