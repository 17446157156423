// HELPER FUNCS
import { hs_breakpoints } from './misc.js'

// GENERATE RANDOM ROUTE
function random_build(state) {

    // EXTRACT ROUTE OPTIONS
    const { races, factions } = state.dataset.options;

    // PICK A RANDOM RACE
    const random_index = Math.floor(Math.random() * races.length);
    const race = races[random_index]

    // PICK A RANDOM BLOCK
    const faction = factions[race]
    const build = state.dataset.routes[faction][race]
    const block = Math.floor(Math.random() * build.length);

    return {
        race, block
    }
}

// GENERATE SPECIFIC ROUTE
function specific_build( state, race, block ) {
    // DEFAULT RESPONSE
    const response = {
        success: false,
        data: {}
    }

    // EXTRACT DATASET STUFF
    const { routes, ids } = state.dataset
    const { races, factions } = state.dataset.options

    // IF THE RACE IS PART OF THE DATASET
    if (races.includes(race)) {

        // LOCATE BUILD DATA
        const faction = factions[race]
        const data = routes[faction][race]
        // CONVERT BLOCK TO A NUMBER
        const parsed_block = parseInt(block)

        // IF THE BLOCK IS WITHIN RANGE
        if (parsed_block >= 0 && parsed_block < data.length) {

            // PARSE HEARTHSTONE BREAKPOINTS
            const hs_data = hs_breakpoints(data)
            
            // UPDATE RESPONSE
            response.success = true
            response.data = {
                route: data,
                race,
                current: parsed_block,
                quests: ids[faction],
                hearthstones: hs_data,
            }
        }
    }
   
    return response
}

export {
    random_build,
    specific_build
}