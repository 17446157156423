import React, { useState } from 'react';
import './interface/css/general.scss';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from "./assets/context";
import Init from './assets/init';
import Pages from './assets/pages';
import Prompt from './components/prompt';

import Create from './components/panel/menu/create';
import Load from './components/panel/menu/load';
import Active from './components/panel/menu/active'

import About from './components/about'

export default () => { 
  
   // WRAPPER STYLE STATE -- DEFAULT TO ACTIVE
   const [local, set_local] = useState('active');
   const baseUrl = `/${process.env.REACT_APP_EXPANSION}/tools/leveling`

      return (
        <BrowserRouter basename={baseUrl}>
            <Provider>
                <Init />
                <div id={ 'menuCreate' } className={ 'menu' }>
                    <Active />
                    <Create />
                    <Load />
                </div>  
                <div id={ 'wrapper' } className={ local }>
                <Pages />
                </div>
                <About /> 
                <Prompt set_wrapper={ set_local } />
            </Provider>
        </BrowserRouter>
      )
 }
