import { useContext, useEffect } from 'react';
import { Context } from "./context";
import { load_routes, load_ids } from '../funcs/database';
import { parse_options } from '../funcs/misc';

export default () => {

    // GLOBAL STATE
    const { dispatch } = useContext(Context);
    // ON INITIAL PAGE LOAD
    useEffect(() => {

        // LOAD ROATES, QUEST IDS & PARSE OPTIONS
        const routes = load_routes(process.env.REACT_APP_EXPANSION)
        const ids = load_ids(process.env.REACT_APP_EXPANSION)
        const options = parse_options(routes)
        // SAVE THEM IN STATE
        dispatch({
            type: 'init',
            payload: {
                routes,
                ids,
                options
            }
        })
    }, [])

    return null;
}