import React, { useState } from 'react';
import '../interface/css/panel.scss';
import '../interface/css/menu.scss';

import Level from './panel/status/level';
import Progress from './panel/status/progress';
import Hearthstone from './panel/status/hearthstone';
import Tab from './panel/tab';
import Objectives from './panel/objectives/list';
import Quests from './panel/quest_log/list';


export default ({ isMobile }) => {

    // LOCAL STATE
    const [local, set_local] = useState({
        objectives: true,
        quests: false,
        isMobile: isMobile
    })

    // TOGGLE PANEL CONTENT
    function toggle() {
        set_local({
            objectives: !local.objectives,
            quests: !local.quests
        })
    }

    return (
        <div id={ 'panel-wrapper' }>
            <div id={ 'panel' }>
                <div id={ 'status' }>
                    <Level />
                    <Progress isMobile={isMobile}/>
                    <Hearthstone />
                    <div id={ 'panel-menu' } className={ 'split' }>
                        <Tab
                            label={ 'Objectives' }
                            func={ toggle }
                            selected={ local.objectives }
                        />
                        <Tab
                            label={ 'Quest Log' }
                            func={ toggle }
                            selected={ local.quests }
                        />
                    </div>
                </div>
                <div id={ 'logs' }>
                    <div id={ 'overflow-fix' }>
                        <Objectives visible={ local.objectives } />
                        <Quests visible={ local.quests } />
                    </div>
                </div>
            </div>
        </div>
    )
}