import React, { useState, useEffect } from 'react';
import blank from '../../interface/images/waypoints/space.png';

export default ({ waypoint, block, resolution, isMobile }) => {


    // LOCAL STATE
    const [local, set_local] = useState({
        alignment: null,
        number: null,
        position: {},
    })

    function getAlignmentId(alignment) {
      return `mapAnchor${alignment}`
    }

    const map = document.getElementById('map')
    // GENERATE APPROPARIATE CONTENT
    useEffect(() => {

        // UPDATE LOCAL STATE
      if (map) {
        let scaleX = (map.clientWidth) / 1440;
        let scaleY = isMobile ?  1 : (scaleX);
        set_local({
        alignment: (waypoint.align === undefined) ? 'mapAnchorleft' : getAlignmentId(waypoint.align),
        number: block + 1,
        position: {
          left: waypoint.coords.x * scaleX + '%',
          top: waypoint.coords.y * scaleY + '%',
        }
      })
      }
      

    }, [waypoint, block, resolution, map.clientWidth])

    return (
        <foreignObject width={'1440px'} height= {'100%'}>
            <div className={ 'vq-waypoint' } style={ local.position }>
                <img
                    src={ blank }
                    id={ waypoint.type }
                    alt={ '' }
                />
                <span id={ local.alignment }>
                    <img
                        src={ blank }
                        id={ 'wp' + local.number }
                        alt={ '' }
                    />
                </span>
            </div>
        </foreignObject>
    )
}