import React, { useContext, useState, useEffect } from 'react';
import { Context } from "../../../assets/context";

export default () => {

    // GLOBAL STATE
    const { state } = useContext(Context);

    // HEARTHSTONE LOCATION
    const [location, set_location] = useState(null)

    // FIND LATEST HEARTHSTONE LOCATION
    useEffect(() => {

        // FILTER OUT RELEVANT HEARTHSTONE LOCATIONS
        const filtered = state.build.hearthstones.filter(id => id.block < state.build.current);
        let value = 'none';

        // IF THERES LOCATIONS FOUND, FETCH THE MOST RECENT ONE
        if (filtered.length !== 0) {
            value = filtered[filtered.length - 1].zone;
        }

        // UPDATE LOCATION
        set_location(value)

    }, [state.build.current, state.build.route])

    return (
        <div id="hearthstone" className="split">
            <div>Hearthstone</div>
            <div>{ location }</div>
        </div>
    )
}