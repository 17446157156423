import React, { useContext, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Context } from './context';

import Specific from '../pages/specific';
import Random from '../pages/random';
import Error from '../pages/error';

export default () => {

    // GLOBAL STATE
    const { state } = useContext(Context);

    // MOBILE STATE
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleWindowSizeChange);

    const isMobile = width <= 768;

    // AFTER DATASET LOADS, RENDER PAGE

    if (state.loaded) {
        // Refresh tooltips (timeout to wait for DOM flush)
        setTimeout(() => {
            if (typeof $dbTooltips !== 'undefined') {
                // eslint-disable-next-line no-undef
                $dbTooltips.refreshLinks();
            }
        }, 10);

        return (
            <div id={!isMobile ? 'vq-wrapper' : 'vq-wrapper-mobile'}>
                <Routes>
                    <Route
                        exact path={'/'}
                        element={<Random />}
                    />
                    <Route
                        path={'/:race'}
                        element={<Specific isMobile={isMobile} />}
                    />
                    <Route
                        path={'*'}
                        element={<Error message={'PAGE NOT FOUND'} />}
                    />
                </Routes>
            </div>

            // OTHERWISE, RENDER LOADING MSG
        )
    } else {
        return (
            <Error message={'LOADING DATASET'} />
        )
    }
}
