import React, { Fragment, useEffect, useState } from 'react';

export default ({ current, next, offcolor, resolution, isMobile }) => {
    
  // LOCAL STATE
  const [local, set_local] = useState({
    scale : 1
  })

  // Lines should be rendered again when resolution is changed.

  useEffect(() => {
      const map = document.getElementById('map')
      
      if (map) {
        let scale = isMobile ? 1 : map.clientWidth / 1440;
        set_local({
          scale: scale
        })
      }

    }, [resolution])
    
    // IF THERE IS A PREDICESSOR
    if (next !== undefined) {
        return (
            <Fragment>
                <line
                    x1={ current.coords.x + '%' }
                    y1={ current.coords.y * local.scale + '%' }
                    x2={ next.coords.x + '%' }
                    y2={ next.coords.y * local.scale + '%' }
                    id={ offcolor ? 'secondary-line-border' : 'primary-line-border' }
                />
                <line
                    x1={ current.coords.x + '%' }
                    y1={ current.coords.y * local.scale + '%' }
                    x2={ next.coords.x + '%' }
                    y2={ next.coords.y * local.scale + '%' }
                    id={ offcolor ? 'secondary-line' : 'primary-line' }
                    />
            </Fragment>
        )

    // OTHERWISE, RETURN NULL
    } else { return null; }
}