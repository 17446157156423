import React, { useContext } from 'react';
import { Context } from "../../../assets/context";
// import { specific_build } from '../../../funcs/build';

import Main from './main';
import Sub from './sub';

export default () => {
    // GLOBAL STATE
    const { state, dispatch } = useContext(Context);

    function deleteCharacter() {

      let progressData = localStorage.getItem(`${process.env.REACT_APP_EXPANSION}-questing`)
      if (progressData) {
        progressData = JSON.parse(progressData)
        delete progressData[state.activeChar]
        localStorage.setItem(`${process.env.REACT_APP_EXPANSION}-questing`, JSON.stringify(progressData))
      }

      dispatch({
        type: 'set-active-character',
        payload: null
      })
    }

    if (state.activeChar) {
      return (
        <Main header={ state.activeChar }>
        <Sub
            header={ 'Delete ' + state.activeChar  }
            icon={ state.build.race }
            func={ deleteCharacter }
        />
        </Main>
      )
    } else {
      return (
        ''
      )
    }
    
}