import React, { useState } from 'react';
import '../interface/css/about.scss';

import wp_green from '../interface/images/waypoints/green.png';
import wp_blue from '../interface/images/waypoints/blue.png';
import wp_yellow from '../interface/images/waypoints/yellow.png';
import wp_red from '../interface/images/waypoints/red.png';
import wp_purple from '../interface/images/waypoints/purple.png';

import color_green from '../interface/images/colors/green.png';
import color_blue from '../interface/images/colors/blue.png';
import color_yellow from '../interface/images/colors/yellow.png';
import color_red from '../interface/images/colors/red.png';
import color_purple from '../interface/images/colors/purple.png'

import chevron_down from '../interface/images/icons/chevron-down.png'

export default () => {

  const [isActive, setIsActive] = useState(false);

    return (
        <div id={ 'about' }>
          <div className="accordion-item">
      <div className="accordion-title" onClick={() => {
        setIsActive(!isActive)
        window.scrollTo({top:400, behavior:'smooth'});
      }}>
        <div className="header" id="features">Features and Usage</div>
        <div><img className={isActive ? 'rotate-180' : 'rotate-0'} src={chevron_down} alt=''/></div>
      </div>
      <div className={isActive ? 'accordion-content' : 'accordion-content-hidden'}>
        <div className="text">
          <div className="secondary">Color Schemes & Icons</div>
          <div id="color-tbl">
            <div><img src= { wp_green } alt=''/></div>
            <div>Flightpath</div>
          </div>
          <div id="color-tbl">
            <div><img src= { wp_blue } alt=''/></div>
            <div>Main Hub</div>
          </div>
          <div id="color-tbl">
            <div>
            <img src= { wp_yellow } alt=''/></div>
            <div>Quest Interaction</div>
          </div>
          <div id="color-tbl">
            <div><img src= {wp_red } alt=''/></div>
            <div>Quest Objective</div>
          </div>
          <div id="color-tbl">
            <div><img src= {wp_purple } alt=''/></div>
            <div>Travel</div>
          </div>
          <br></br>
          <div id="color-tbl">
            <div><img src={color_green} id="border" alt=''/></div>
            <div>End Quest</div>
          </div>
          <div id="color-tbl">
            <div><img src={color_yellow} id="border" alt=''/></div>
            <div>Start Quest</div>
          </div>
          <div id="color-tbl">
            <div><img src={color_red} id="border" alt=''/></div>
            <div>Do the Quest</div>
          </div>
          <div id="color-tbl">
            <div><img src={color_blue} id="border" alt=''/></div>
            <div>Special Note</div>
          </div>
          <div id="color-tbl">
            <div><img src={color_purple} id="border" alt=''/></div>
            <div>Chat Quest</div>
          </div>
          
          <br></br>
          <div id="color-tbl"><div>[P]</div><div>Part # of the Chain</div></div>
          <div id="color-tbl"><div>[E]</div><div>Elite Quest</div></div>
          <div id="color-tbl"><div>[D]</div><div>Dungeon Quest</div></div>
          <div id="color-tbl"><div>[F]</div><div>Escort Quest</div></div>
          <div id="color-tbl"><div>[R]</div><div>Random Drop Quest Starter</div></div>
          <div id="color-tbl"><div>[C]</div><div>Class Quest</div></div>
          <br></br>
          <div className="secondary">Save & Load Progress</div>
            Initially the application loads up a completely random dataset that’s meant to work as an introduction to
            everything. You can create a new character profile from the <span>upper right menu</span> to automatically
            save your accumulated progress locally in your browsers’ memory. The application keeps tracks your storage so
            loading up a character profiles progress is effortless.
         <br></br>
        </div>
      </div>
      
    </div>
        </div>
    )
}