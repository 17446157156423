import { shorten } from '../funcs/misc';

// PARSE QUESTLOG
function filter(state) {

    // QUEST CONTAINER
    const container = new Map();

    // LOOP THROUGH DATA TO CURRENT BLOCK
    for (let x = 0; x < state.build.current; x++) {

        // SHORTHAND
        const waypoints = state.build.route[x].waypoints;

        // LOOP THROUGH EACH WAYPOINT
        waypoints.forEach(waypoint => {

            // REMOTE QUESTS THAT HAVE ENDED
            if (waypoint.ends !== undefined) {
                waypoint.ends.forEach(quest => {

                    // EXTRACT QUEST NAME & REMOVE IT
                    const name = quest_name(quest);
                    container.delete(name);
                })
            }

            // ADD QUESTS THAT HAVE BEEN STARTED
            if (waypoint.starts !== undefined) {
                waypoint.starts.forEach(quest => {

                    // EXTRACT QUEST NAME & PUSH IT
                    const name = quest_name(quest);
                    container.set(name, quest)
                })
            }
        })
    }

    // CONVERT TO ARRAY & RETURN
    return Array.from(container.values());
}

// FIND QUEST NAME
function quest_name(quest) {
    switch (quest instanceof Array) {
        case true: { return quest[0] }
        default: { return quest; }
    }
}

// EXTRACT QUEST NAME & ID
function extract(data, state) {

    // NAME PLACEHOLDER & ID
    let name = quest_name(data);
    const id = fetch_id(data, state.build.quests);

    return {
        name: shorten(name),
        id: id
    }
}

// FETCH QUEST ID FOR SIDEPANEL LINKS
function fetch_id(quest, quests) {

    // FETCH NAME & TARGET DATA -- FORCE LOWERCASE
    const name = quest_name(quest).toLowerCase();
    const target = quests[name];

    // PLACEHOLDER
    let split;

    // SPLIT IF THERE ARE MORE THAN ONE ELEMENT
    if (typeof quest === 'object' && quest.length > 1) {
        split = quest[1].split('-');

    // OTHERWISE, RETURN FIRST INDEX VALUE
    } else { split = quest[0] }

    // FETCH ID
    const id = parseInt(split[0].replace(/[^0-9]/g, ''));

    // IF THE QUEST DOESNT EXIST
    if (target === undefined) {
        console.log(name + ' ID was not found');
        return 2;
    }

    // DEFAULT TO STANDALONE QUEST
    let response = target;

    // IF IT HAS A TYPE
    if (typeof target === 'object') {
        switch (isNaN(id)) {

            // THE ID WAS VALID
            case false:
                response = target[id - 1];
            break;

            // OTHERWISE, DEFAULT TO FIRST INDEX
            default:
                response = target[0]
                console.log(name + ' found an indexless array')
            break;
        }
    }

    return response;
}

export {
    filter,
    extract
}